import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import hero from "../assets/images/heroWeb.png";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import featureWeb from "../assets/images/featureWeb.png";
import featureWebS from "../assets/images/featureWebS.png";
import featureWebMobile from "../assets/images/featureWebMobile.png";
import featureWebMobileS from "../assets/images/featureWebMobileS.png";
import Faq from "../components/Faq";
import { useTranslation } from "react-i18next";

const Web = () => {
  const { t, i18n } = useTranslation();

  const textHero = t("hero.web", { returnObjects: true });
  const text = t("web", { returnObjects: true });
  const faqList = t("faq.web.list", { returnObjects: true });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="web">
      <section className="hero">
        <div className="hero__header">
          <img
            src={headerBg}
            alt=""
            style={{ width: "80vw", maxWidth: "800px" }}
          />
          <h1 className="hero__header__title">
            <span>{textHero.title}</span>
          </h1>
        </div>

        <p className="hero__disc" style={{ maxWidth: 550 }}>
          {textHero.disc}
        </p>

        <div className="hero__action">
          <HashLink to="#pricing">
            <button>
              <span>{t("getStartedBtn")}</span>
            </button>
          </HashLink>

          <p>{textHero.price}</p>
        </div>

        <img
          src={hero}
          className="hero__img vpsHero"
          alt=""
          style={{ marginTop: 27 }}
        />
      </section>

      <section className="web__essential">
        <h2
          className="whyWork__main__title"
          style={{ flexWrap: "wrap" }}
          dangerouslySetInnerHTML={{ __html: text.essential.title }}
        ></h2>
        <p className="web__essential__disc">{text.essential.disc}</p>

        <div className="web__essential__list">
          {text.essential.list.map((item, i) => {
            return (
              <div
                key={item.title}
                className={
                  i === 2
                    ? "web__essential__list__item big"
                    : "web__essential__list__item"
                }
              >
                <h4>{item.title}</h4>
                <p>{item.disc}</p>
              </div>
            );
          })}
        </div>
      </section>

      <div className="web__priceBg">
        <section className="web__feature">
          <img src={i18n.language === "en" ? featureWeb : featureWebS} alt="" />
        </section>
        <section className="web__featureMobile">
          <h2 className="whyWork__main__title" style={{ flexWrap: "wrap" }}>
            <span>{text.feature.titleM}</span>
          </h2>

          <img
            src={i18n.language === "en" ? featureWebMobile : featureWebMobileS}
            alt=""
          />
        </section>

        <section className="web__price" id="pricing">
          <div className="web__price__main">
            <div className="web__price__left">
              <h4>{text.price.title}</h4>
              <p>{text.price.disc}</p>

              <button className="btn">
                <span>{text.price.btn}</span>
              </button>
            </div>

            <div className="web__price__right">
              <div>
                <span className="web__price__right__start">
                  {text.price.start}
                </span>
                <div className="web__price__right__main">
                  <p>{text.price.sign}</p>{" "}
                  <div className="web__price__right__main__amount">
                    {" "}
                    <b>{text.price.amount}</b>
                    <span>/{text.price.period}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Faq faqList={faqList} alt={true} />
      </div>
    </div>
  );
};

export default Web;
