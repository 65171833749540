import hero from "../assets/images/emailHero.png";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import emailMag1 from "../assets/images/svg/emailMag1.svg";
import emailMag2 from "../assets/images/svg/emailMag2.svg";
import emailMag3 from "../assets/images/svg/emailMag3.svg";
import emailF1 from "../assets/images/svg/emailF1.svg";
import emailF2 from "../assets/images/svg/emailF2.svg";
import emailF3 from "../assets/images/svg/emailF3.svg";
import emailF4 from "../assets/images/svg/emailF4.svg";
import emailF5 from "../assets/images/svg/emailF5.svg";
import emailF6 from "../assets/images/svg/emailF6.svg";
import Plan from "../components/Plan";
import Faq from "../components/Faq";
import eclipes from "../assets/images/arcWhy.png";
import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";

const Email = () => {
  const { t } = useTranslation();

  const textHero = t("hero.email", { returnObjects: true });
  const text = t("email", { returnObjects: true });
  const planList = t("plan.email.list", { returnObjects: true });
  const faqList = t("faq.email.list", { returnObjects: true });

  const handleListImages = (index) => {
    const num = index + 1;
    if (num === 1) {
      return emailF1;
    } else if (num === 2) {
      return emailF2;
    } else if (num === 3) {
      return emailF3;
    } else if (num === 4) {
      return emailF6;
    } else if (num === 5) {
      return emailF4;
    } else {
      return emailF5;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="email">
      <section className="hero" style={{ paddingBottom: 0 }}>
        <div className="hero__header">
          <img
            src={headerBg}
            alt=""
            style={{ width: "80vw", maxWidth: "800px" }}
          />
          <h1 className="hero__header__title">
            <span>{textHero.title1} </span>
            <div>{textHero.title2}</div>
          </h1>
        </div>

        <p className="hero__disc" style={{ maxWidth: 500 }}>
          {textHero.disc}
        </p>
        <div className="hero__action">
          <HashLink to="#pricing">
            <button>
              <span>{t("getStartedBtn")}</span>
            </button>
          </HashLink>
          <p>{textHero.price}</p>
        </div>

        <img src={hero} className="hero__img" alt="" />
      </section>

      <section className="email__mag">
        <h2 className="whyWork__main__title" style={{ letterSpacing: "-1px" }}>
          <span>{text.mag.title}</span>
        </h2>

        <p className="email__mag__disc">{text.mag.disc}</p>
        <div className="email__mag__main">
          {text.mag.list?.map((item, i) => {
            return (
              <div className="email__mag__main__item" key={item.title}>
                <img
                  src={i === 0 ? emailMag2 : i === 1 ? emailMag3 : emailMag1}
                  alt=""
                />
                <div className="email__mag__main__item__text">
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="whyWork alt">
        <div className="whyWork__main">
          <h2 className="whyWork__main__title">
            <span>{text.whywork.title}</span>
          </h2>

          <div className="whyWork__main__arc">
            <img src={eclipes} className="whyWork__main__arc__img alt" alt="" />
            <p className="whyWork__main__disc alt">{text.whywork.disc}</p>

            <div className="whyWork__main__list alt">
              {text.whywork.list?.map((item, i) => {
                return (
                  <div key={item.title} className="whyWork__main__list__item">
                    <img
                      src={handleListImages(i)}
                      alt=""
                      className={i === 3 ? "altImg small" : "altImg"}
                    />
                    <h4>{item.title}</h4>
                    <p className="alt">{item.disc}</p>
                  </div>
                );
              })}
            </div>
            <div className="whyWork__main__list__mobile">
              <Swiper
                slidesPerView={1}
                spaceBetween={20}
                pagination={true}
                loop={true}
                modules={[Pagination, Navigation]}
                style={{ paddingBottom: 30 }}
                navigation={true}
              >
                {text.whywork.list?.map((item, i) => {
                  return (
                    <SwiperSlide key={item.title}>
                      <div
                        style={{ width: "70%", margin: "0 auto" }}
                        className="whyWork__main__list__item"
                      >
                        <img
                          src={item.img}
                          alt=""
                          className={i === 3 ? "altImg small" : "altImg"}
                        />
                        <h4>{item.title}</h4>
                        <p className="alt">{item.disc}</p>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="whyWork__main__btn">
              <button>
                <span>{t("getStartedBtn")}</span>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Plan planList={planList} email={true} alt={true} />

      <Faq faqList={faqList} alt={true} />
    </div>
  );
};

export default Email;
