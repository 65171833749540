import { useTranslation } from "react-i18next";
import social1 from "../assets/images/svg/social1.svg";
import social2 from "../assets/images/svg/social2.svg";
import social3 from "../assets/images/svg/social3.svg";

const Footer = ({ alt }) => {
  const { t } = useTranslation();
  const text = t("footer", { returnObjects: true });

  return (
    <footer className={alt ? "footer alt" : "footer"}>
      <div className="footer__main">
        <div className="footer__left">
          <p>{text.copy}</p>
          <p>{text.policy}</p>
          <p>{text.term}</p>
        </div>

        <div className="footer__right">
          <a
            href="https://www.instagram.com/metoda_srbija/?utm_source=qr&igsh=cnA1dzhwNGIzZTkx"
            target="_blank"
            rel="noreferrer"
            aria-label="Go to our instagram page"
          >
            <img src={social1} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/company/metoda-rs/about/?viewAsMember=true"
            target="_blank"
            rel="noreferrer"
            aria-label="Go to our linkedin page"
          >
            <img src={social2} alt="" />
          </a>
          <a
            href="https://x.com/metoda_srbija"
            target="_blank"
            rel="noreferrer"
            aria-label="Go to our X (twitter) page"
          >
            <img src={social3} alt="" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
