import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import React from "react";
import arrowDown from "../assets/images/svg/arrowDown.svg";
import arrowDownAlt from "../assets/images/svg/arrowDownAlt.svg";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const LinkSubMenu = ({ link }) => {
  const { pathname } = useLocation();

  return (
    <li>
      <Menu
        menuButton={
          <div
            className={
              pathname.includes("services")
                ? "topbar__right__links__item alt"
                : "topbar__right__links__item"
            }
          >
            <div className="topbar__right__links__item__down">
              {link?.name}
              <img src={arrowDown} alt="" />
            </div>
            <span className="topbar__right__links__item__dot">...</span>
          </div>
        }
        menuClassName="topbar__right__links__menu"
      >
        <img
          className="topbar__right__btn__popup__icon"
          src={arrowDown}
          alt=""
          style={{
            left: 68.8,
          }}
        />
        {link?.subLinks?.map((subLink) => {
          if (subLink?.subLinks) {
            return (
              <SubMenu
                key={subLink?.name}
                label={
                  <p
                    key={subLink?.name}
                    className="topbar__right__links__submenu__label"
                  >
                    <Link
                      // style={{ width: "100%" }}
                      to={subLink?.url}
                    >
                      {subLink?.name}
                    </Link>{" "}
                    <img src={arrowDownAlt} alt="" />
                  </p>
                }
                // menuClassName="topbar__right__links__submenu"
                gap={28}
                shift={-8}
              >
                <img
                  className="topbar__right__links__popupIcon"
                  src={arrowDown}
                  alt=""
                />
                {subLink?.subLinks?.map((item) => {
                  return (
                    <Link to={item?.url} key={item?.name}>
                      {" "}
                      <MenuItem>{item?.name}</MenuItem>{" "}
                    </Link>
                  );
                })}
              </SubMenu>
            );
          } else {
            return (
              <Link to={subLink?.url} key={subLink?.name}>
                <MenuItem>{subLink?.name}</MenuItem>{" "}
              </Link>
            );
          }
        })}
      </Menu>
    </li>
  );
};

export default LinkSubMenu;
