import React, { useState } from "react";
import eye from "../assets/images/svg/eye.svg";
import eyeOff from "../assets/images/svg/eyeOff.svg";
import { useTranslation } from "react-i18next";

const Signup = () => {
  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  const text = t("auth.signupP", { returnObjects: true });
  return (
    <div className="signup">
      <div className="signup__main">
        <h1>{text.create}</h1>

        <div className="signup__form">
          <div className="signup__form__item">
            <input type="email" placeholder={text.email} />
          </div>
          <div className="signup__form__item alt">
            <input type="text" placeholder={text.username} />
          </div>
          <div className="signup__form__item">
            <input
              type={show ? "text" : "password"}
              placeholder={text.password}
            />
            <span onClick={() => setShow((curr) => !curr)}>
              <img src={show ? eyeOff : eye} alt="" />{" "}
              {show ? text.hide : text.show}
            </span>
          </div>

          <div className="signup__form__offers">
            <p>{text.offers}</p>
            <div className="signup__form__offers__btns">
              <button className="alt"> {text.accept}</button>
              <button> {text.decline}</button>
            </div>
          </div>

          <div className="signup__form__btn">
            <button className="btn">
              <span>{text.create}</span>
            </button>
          </div>

          <p className="signup__form__terms">
            {text.agree} <span>{text.agree2}</span> {text.agree3}{" "}
            <span>{text.agree4}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
