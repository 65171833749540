import React, { useEffect } from "react";
import treeIcon1 from "../assets/images/svg/treeIcon1.svg";
import treeIcon2 from "../assets/images/svg/treeIcon2.svg";
// import treeIcon3 from "../assets/images/svg/treeIcon3.svg";
import treeIcon4 from "../assets/images/treeIcon4.png";
import heroVps from "../assets/images/svg/heroVps.bg.svg";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import Plan from "../components/Plan";
import Faq from "../components/Faq";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

const Vps = () => {
  const { t } = useTranslation();

  const textHero = t("hero.vps", { returnObjects: true });
  const text = t("vps", { returnObjects: true });
  const planList = t("plan.vps.list", { returnObjects: true });
  const faqList = t("faq.vps.list", { returnObjects: true });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="vps">
      <div className="vps__blur">
        <section className="hero">
          <div className="hero__header">
            <img
              src={headerBg}
              alt=""
              style={{ width: "80vw", maxWidth: "800px" }}
            />
            <h1 className="hero__header__title">
              <span>{textHero.title1} </span>
              <div>{textHero.title2}</div>
            </h1>
          </div>

          <p className="hero__disc" style={{ maxWidth: 500 }}>
            {textHero.disc}
          </p>
          <div className="hero__action" style={{ marginBottom: 0 }}>
            <HashLink to="#pricing">
              <button>
                <span>{t("getStartedBtn")}</span>
              </button>
            </HashLink>
            <p>{textHero.price}</p>
          </div>

          <img src={heroVps} className="hero__img vpsHero" alt="" />
        </section>

        <section className="vps__design">
          <h2
            className="whyWork__main__title"
            style={{ letterSpacing: "-1px" }}
          >
            <span>{text.design.title1}</span> {text.design.title2}{" "}
            <span>{text.design.title3}</span>
          </h2>

          <p className="vps__design__disc">{text.design.disc}</p>

          <div className="vps__design__tree">
            <div className="vps__design__tree__midline"></div>

            <div className="vps__design__tree__item">
              <div className="vps__design__tree__item__main">
                <div className="vps__design__tree__item__main__top">
                  <h4>{text.design.tree.title1}</h4>

                  <img src={treeIcon1} alt="" />
                </div>

                <p>{text.design.tree.disc1}</p>
              </div>

              <div className="vps__design__tree__item__line"></div>
            </div>
            <div className="vps__design__tree__item alt">
              <div className="vps__design__tree__item__line"></div>

              <div className="vps__design__tree__item__main">
                <div className="vps__design__tree__item__main__top">
                  <h4>{text.design.tree.title2}</h4>

                  <img src={treeIcon4} alt="" />
                </div>

                <p>{text.design.tree.disc2}</p>
              </div>
            </div>
            <div className="vps__design__tree__item alt2">
              <div className="vps__design__tree__item__main ">
                <div className="vps__design__tree__item__main__top">
                  <h4>{text.design.tree.title3}</h4>

                  <img src={treeIcon2} alt="" />
                </div>

                <p>{text.design.tree.disc3}</p>
              </div>

              <div className="vps__design__tree__item__line"></div>
            </div>
          </div>
        </section>

        <section className="vps__control">
          <div className="vps__control__top">
            <h2
              className="whyWork__main__title"
              style={{ letterSpacing: "-1px" }}
              dangerouslySetInnerHTML={{
                __html: text.control.title,
              }}
            ></h2>
            <p>{text.control.disc}</p>
          </div>

          <div className="vps__control__list">
            {text.control.list?.map((item, i) => {
              return (
                <div className="vps__control__list__item" key={item.title}>
                  <span>{i + 1}</span>
                  <h4>{item.title}</h4>
                  <p>{item.disc} </p>
                </div>
              );
            })}
          </div>
        </section>
      </div>

      <Plan planList={planList} alt={true} vps={true} />

      <Faq faqList={faqList} alt={true} />
    </div>
  );
};

export default Vps;
