// import React, { useEffect, useState } from "react";
import menuIcon from "../assets/images/svg/menu.svg";
// import ReactModal from "react-modal";
// import close from "../assets/images/svg/close.svg";
// import { useLocation } from "react-router-dom";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import arrowDown from "../assets/images/svg/arrowDown.svg";
import { Link } from "react-router-dom";
import arrowDownAlt from "../assets/images/svg/arrowDownAlt.svg";
import { HashLink } from "react-router-hash-link";

const Drawer = ({ links }) => {
  // const [modalIsOpen, setIsOpen] = useState(false);
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   setIsOpen(false);
  // }, [pathname]);

  return (
    <>
      {/* <div className="topbar__menu" onClick={() => setIsOpen(true)}>
        <img src={menuIcon} alt="" />
      </div> */}
      <Menu
        menuButton={
          <div className="topbar__menu">
            <img src={menuIcon} alt="" />
          </div>
        }
        menuClassName="topbar__right__links__menu alt"
      >
        <img
          className="topbar__right__btn__popup__icon"
          src={arrowDown}
          alt=""
          style={{
            left: 5,
          }}
        />

        {links?.map((subLink) => {
          if (subLink?.subLinks) {
            return (
              <SubMenu
                key={subLink?.name}
                label={
                  <p
                    key={subLink?.name}
                    className="topbar__right__links__submenu__label"
                  >
                    {subLink?.name}

                    <img src={arrowDownAlt} alt="" />
                  </p>
                }
                // menuClassName="topbar__right__links__submenu"
                gap={28}
                shift={-8}
              >
                <img
                  className="topbar__right__links__popupIcon"
                  src={arrowDown}
                  alt=""
                />
                {subLink?.subLinks?.map((item) => {
                  if (item?.subLinks) {
                    return (
                      <SubMenu
                        key={item?.name}
                        label={
                          <p
                            key={item?.name}
                            className="topbar__right__links__submenu__label"
                          >
                            <Link
                              // style={{ width: "100%" }}
                              to={item?.url}
                            >
                              {item?.name}
                            </Link>{" "}
                            <img src={arrowDownAlt} alt="" />
                          </p>
                        }
                        // menuClassName="topbar__right__links__submenu"
                        gap={28}
                        shift={-8}
                      >
                        <img
                          className="topbar__right__links__popupIcon"
                          src={arrowDown}
                          alt=""
                        />
                        {item?.subLinks?.map((subItem) => {
                          return (
                            <Link to={subItem?.url} key={subItem?.name}>
                              {" "}
                              <MenuItem>{subItem?.name}</MenuItem>{" "}
                            </Link>
                          );
                        })}
                      </SubMenu>
                    );
                  } else {
                    return (
                      <Link to={item?.url} key={item?.name}>
                        {" "}
                        <MenuItem>{item?.name}</MenuItem>{" "}
                      </Link>
                    );
                  }
                })}
              </SubMenu>
            );
          } else {
            return (
              <HashLink to={subLink?.url} key={subLink?.name}>
                <MenuItem>{subLink?.name}</MenuItem>{" "}
              </HashLink>
            );
          }
        })}
      </Menu>

      {/* <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Drawer Modal"
        overlayClassName="modal-overlay"
        className="modalMain"
      >
        <div className="drawer__modal">
          <div className="drawer__modal__close">
            <img src={close} alt="" onClick={() => setIsOpen(false)} />
          </div>
          <TopBarLinks drawer={true} setIsOpen={setIsOpen} />
        </div>
      </ReactModal> */}
    </>
  );
};

export default Drawer;
