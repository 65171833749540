import React, { useEffect } from "react";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import heroBgD from "../assets/images/svg/heroBgD.svg";
import dedicatedY1 from "../assets/images/svg/dedicatedY1.svg";
import dedicatedY2 from "../assets/images/svg/dedicatedY2.svg";
import dedicatedY3 from "../assets/images/svg/dedicatedY3.svg";
import dedicatedF1 from "../assets/images/svg/dedicatedF1.svg";
import dedicatedF2 from "../assets/images/svg/dedicatedF2.svg";
// import dedicatedF3 from "../assets/images/svg/dedicatedF3.svg";
import dedicatedF4 from "../assets/images/svg/dedicatedF4.svg";
// import dedicatedF5 from "../assets/images/svg/dedicatedF5.svg";
import dedicatedF6 from "../assets/images/svg/dedicatedF6.svg";
import dedicatedF7 from "../assets/images/svg/dedicatedF7.svg";
import dedicatedF8 from "../assets/images/treeIcon4.png";

import Faq from "../components/Faq";
import Plan from "../components/Plan";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

const Dedicated = () => {
  const { t } = useTranslation();

  const textHero = t("hero.dedicated", { returnObjects: true });
  const text = t("dedicated", { returnObjects: true });
  const planList = t("plan.dedicated.list", { returnObjects: true });
  const faqList = t("faq.dedicated.list", { returnObjects: true });

  const handleListImages = (index) => {
    const num = index + 1;
    if (num === 1) {
      return dedicatedF2;
    } else if (num === 2) {
      return dedicatedF4;
    } else if (num === 3) {
      return dedicatedF7;
    } else if (num === 4) {
      return dedicatedF1;
    } else if (num === 5) {
      return dedicatedF8;
    } else {
      return dedicatedF6;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="dedicated">
      <section className="hero">
        <div className="hero__header">
          <img
            src={headerBg}
            alt=""
            style={{ width: "80vw", maxWidth: "800px" }}
          />
          <h1 className="hero__header__title">
            <span>{textHero.title1} </span>
            <div>{textHero.title2}</div>
          </h1>
        </div>

        <p className="hero__disc" style={{ maxWidth: "450px" }}>
          {textHero.disc}
        </p>

        <div className="hero__action" style={{ marginBottom: 10 }}>
          <HashLink to="#pricing">
            <button>
              <span>{t("getStartedBtn")}</span>
            </button>
          </HashLink>

          <p>{textHero.price}</p>
        </div>

        <img src={heroBgD} className="hero__img vpsHero" alt="" />
      </section>

      <div className="dedicated__bgBlur">
        <section className="dedicated__you">
          <h2
            className="whyWork__main__title"
            dangerouslySetInnerHTML={{ __html: text.you.title }}
          ></h2>

          <p className="dedicated__you__disc">{text.you.disc}</p>

          <div className="dedicated__you__list">
            {text.you.list.map((item, i) => {
              return (
                <div
                  className={
                    i === 2
                      ? "dedicated__you__list__item last"
                      : "dedicated__you__list__item"
                  }
                  key={item.title}
                >
                  <img
                    src={
                      i === 0
                        ? dedicatedY1
                        : i === 1
                        ? dedicatedY2
                        : dedicatedY3
                    }
                    alt=""
                  />

                  {item.html ? (
                    <h4 dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                  ) : (
                    <h4>{item.title}</h4>
                  )}
                  <p>{item.disc}</p>
                </div>
              );
            })}
          </div>
        </section>

        <section className="dedicated__features">
          <h2 className="whyWork__main__title">
            <span>{text.feature.title}</span>
          </h2>

          <p className="dedicated__you__disc">{text.feature.disc}</p>

          <div className="dedicated__features__list">
            {text.feature.list?.map((item, i) => {
              return (
                <div
                  className="dedicated__features__list__item"
                  key={item.title}
                >
                  <img src={handleListImages(i)} alt="" />
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                </div>
              );
            })}
          </div>

          <button className="dedicated__features__btn">
            <span>{t("getStartedBtn")}</span>
          </button>
        </section>
      </div>

      <Plan planList={planList} alt={true} />

      <Faq faqList={faqList} alt={true} />
    </div>
  );
};

export default Dedicated;
