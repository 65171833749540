import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import sharedHero from "../assets/images/sharedHero.png";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import sharedR1 from "../assets/images/svg/sharedR1.svg";
import sharedR2 from "../assets/images/svg/sharedR2.svg";
import sharedR3 from "../assets/images/svg/sharedR3.svg";
import sharedF1 from "../assets/images/svg/sharedF1.svg";
import sharedF2 from "../assets/images/svg/sharedF2.svg";
import sharedF3 from "../assets/images/svg/sharedF3.svg";
import sharedF4 from "../assets/images/svg/sharedF4.svg";
import sharedF5 from "../assets/images/svg/sharedF5.svg";
import Plan from "../components/Plan";
import Faq from "../components/Faq";
import { useTranslation } from "react-i18next";

const Shared = () => {
  const [feature, setFeature] = useState({});

  const { t } = useTranslation();

  const textHero = t("hero.shared", { returnObjects: true });
  const text = t("shared", { returnObjects: true });
  const planList = t("plan.shared.list", { returnObjects: true });
  const faqList = t("faq.shared.list", { returnObjects: true });

  const handleListImages = (index) => {
    const num = index + 1;
    if (num === 1) {
      return sharedF4;
    } else if (num === 2) {
      return sharedF2;
    } else if (num === 3) {
      return sharedF5;
    } else if (num === 4) {
      return sharedF1;
    } else {
      return sharedF3;
    }
  };

  useEffect(() => {
    setFeature({ ...text.feature.list[0], icon: sharedF4 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="shared">
      <div className="shared__blur">
        <section className="hero">
          <div className="hero__header">
            <img
              src={headerBg}
              alt=""
              style={{ width: "80vw", maxWidth: "800px" }}
            />
            <h1 className="hero__header__title">
              <span>{textHero.title}</span>
            </h1>
          </div>

          <p className="hero__disc">{textHero.disc}</p>
          <div className="hero__action">
            <HashLink to="#pricing">
              <button>
                <span>{t("getStartedBtn")}</span>
              </button>
            </HashLink>

            <p>{textHero.price}</p>
          </div>

          <img src={sharedHero} className="hero__img" alt="" />
        </section>
        <section className="shared__reason">
          <h2 className="whyWork__main__title" style={{ gap: "7px" }}>
            <span>{text.reason.title1}</span> {text.reason.title2}
          </h2>
          <p className="shared__reason__disc">{text.reason.disc}</p>

          <div className="shared__reason__list">
            {text.reason.list?.map((item, i) => {
              return (
                <div key={item.title} className="shared__reason__list__item">
                  <img
                    src={i === 0 ? sharedR1 : i === 1 ? sharedR2 : sharedR3}
                    alt=""
                  />
                  <div className="shared__reason__list__item__right">
                    <h4>{item.title}</h4>
                    <p>{item.disc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </section>

        <section className="shared__feature">
          <h2 className="whyWork__main__title" style={{ gap: "7px" }}>
            <span>{text.feature.title}</span>
          </h2>

          <div className="shared__feature__list">
            {text.feature.list?.map((item, i) => {
              return (
                <div
                  key={item.title}
                  className={
                    feature.title === item.title
                      ? "shared__feature__list__item alt"
                      : "shared__feature__list__item"
                  }
                  onClick={() =>
                    setFeature({ ...item, icon: handleListImages(i) })
                  }
                >
                  <img src={handleListImages(i)} alt="" />
                  <h4>{item.title}</h4>
                </div>
              );
            })}
          </div>

          <div className="shared__feature__main">
            <img src={feature.icon} alt="" />
            <div className="shared__feature__main__right">
              <h4>{feature.title}</h4>
              <p>{feature.disc}</p>
            </div>
          </div>
        </section>
      </div>

      <Plan planList={planList} alt={true} shared={true} />

      <Faq faqList={faqList} alt={true} />
    </div>
  );
};

export default Shared;
